/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Shared
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import Plaatjie from '@ubo/plaatjie'

// Images
import Logo from 'img/logowhite.inline.svg'
import Chevron from 'img/chevron.inline.svg'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.light};
`

const Brand = styled(NavLink)`
  & > svg {
    height: 45px;
    width: 159px;
  }
`

const LinksAbove = styled.div`
  & a > div {
    font-weight: ${({ theme }) => theme.font.weight.bold} !important;
    &:hover {
      text-decoration: underline;
    }
    @media (max-width: 1199px) {
      font-size: 14px;
    }
  }
  & svg {
    width: 9px;
    height: 14px;
    & path {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }
`

const LinksBelow = styled.div`
  width: fit-content;
  & a:hover {
    color: ${({ theme }) => theme.color.secondary};
    text-decoration: underline;
  }

  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`

const Socials = styled.div`
  & a {
    font-size: 0;
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
  }

  & a:last-child {
    img {
      width: 18px;
    }
  }
`

const Content = styled(ParseContent)`
  & a {
    color: ${({ theme }) => theme.color.light};
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => theme.color.secondary};
      text-decoration: underline;
    }
  }
`

const Image = styled(Plaatjie)`
  width: 126px;
  height: 48px;
`

const Table = styled.table`
  width: 110%;
`

const FooterRight = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    align-items: flex-start !important;
  }

  flex-direction: column;
  gap: 2rem;
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooterLeft {
            descriptions {
              description
            }
            socials {
              icon {
                altText
                localFile {
                  publicURL
                }
              }
              link {
                title
                url
              }
            }
          }
          menuFooterRight {
            linksabove {
              link {
                title
                url
              }
            }
            linksbelow {
              link {
                title
                url
              }
            }
            partners {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                  }
                }
              }
              link {
                title
                url
              }
            }
          }
          openingtimes {
            day
            time
          }
        }
      }
    }
  `)

  const { menuFooterLeft, menuFooterRight, openingtimes }: any = fields?.footer

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.trustoo.nl/widget/widget_v2.js'
    script.async = true
    document.body.prepend(script)

    return () => {
      // Clean up script tag when component unmounts
      document.body.removeChild(script)
    }
  }, [])

  return (
    <StyledFooter>
      <div className="container py-5">
        <div className="row justify-content-between mb-5">
          <div className="col-lg-6 col-xl-5 mb-5 mb-lg-0">
            <Brand to="/">
              <Logo />
            </Brand>
          </div>
          <div className="col-lg-6 d-flex align-items-end">
            <LinksAbove className="d-flex flex-column flex-sm-row justify-content-between w-100">
              {menuFooterRight.linksabove.map((l: any, index: number) => (
                <NavLink
                  key={index}
                  to={l.link.url}
                  className="d-flex align-items-center"
                >
                  <Chevron className="me-3" />
                  <ParseContent content={l.link.title} />
                </NavLink>
              ))}
            </LinksAbove>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-lg-6 col-xl-5">
            <div className="row flex-column flex-sm-row justify-content-between mb-lg-5">
              {menuFooterLeft.descriptions.map((d: any, index: number) => (
                <div key={index} className="col-lg-6">
                  <Content
                    key={index}
                    content={d.description}
                    className="mb-3 mb-sm-0"
                  />
                </div>
              ))}
            </div>
            <Socials className="d-flex align-items-center my-4 my-lg-0">
              {menuFooterLeft.socials.map((s: any, index: number) => (
                <a
                  key={index}
                  href={s.link.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {s.link.title}
                  <Plaatjie
                    image={s.icon}
                    className="me-3"
                    alt={s.icon.altText}
                  />
                </a>
              ))}
            </Socials>
          </div>
          <div className="col-lg-6 d-flex justify-content-between flex-wrap">
            <LinksBelow className="d-flex flex-column">
              {menuFooterRight.linksbelow.map((l: any, index: number) => (
                <NavLink key={index} to={l.link.url} className="mb-2">
                  {l.link.title}
                </NavLink>
              ))}
            </LinksBelow>
            <div className="mb-4">
              <p className="mb-0">
                <strong>Openingstijden</strong>
              </p>
              <Table>
                {openingtimes?.map((time: any, index: number) => (
                  <tr key={index}>
                    <td>{time?.day}</td>
                    <td>{time?.time}</td>
                  </tr>
                ))}
              </Table>
            </div>
            <FooterRight className="d-flex align-items-end">
              <div
                className="trustoo-widget"
                data-id="70650"
                data-language-code="nl"
                data-country-code="NL"
                data-badge="default"
                data-quote="default"
                data-reviews="default"
                data-size="small"
                data-type="portrait"
                data-border="hidden"
                data-theme="dark"
                data-background="transparent"
              />
              {menuFooterRight.partners.map((p: any, index: number) => (
                <div key={index}>
                  <a href={p.link.url} target="_blank" rel="noreferrer">
                    <Image image={p.image} alt="" />
                  </a>
                </div>
              ))}
            </FooterRight>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
